// extracted by mini-css-extract-plugin
export var headMarketplace = "single-module--headMarketplace--2w1PL";
export var headMarketplaceWrapper = "single-module--headMarketplaceWrapper--2vbuF";
export var headMarketplaceTitle = "single-module--headMarketplaceTitle--ManYX";
export var headMarketplaceLogoContainer = "single-module--headMarketplaceLogoContainer--3oh6X";
export var headMarketplaceLogoCircle = "single-module--headMarketplaceLogoCircle--1hTeh";
export var headMarketplaceLogoCircleFit = "single-module--headMarketplaceLogoCircleFit---9eVX";
export var headMarketplaceButtons = "single-module--headMarketplaceButtons--2wuNt";
export var button = "single-module--button--1cmAj";
export var buttonHollow = "single-module--buttonHollow--1GjWV";
export var wrapper = "single-module--wrapper--3dp1p";
export var formBlock = "single-module--formBlock--2vdxL";
export var formContainer = "single-module--formContainer--2QaCF";
export var formTitle = "single-module--formTitle--2GY0I";
export var formSubtitle = "single-module--formSubtitle--1mvyS";
export var formScrollTo = "single-module--formScrollTo--1rzfZ";
export var formScrollToTitle = "single-module--formScrollToTitle--3zGzt";
export var headMarketplaceSubtitle = "single-module--headMarketplaceSubtitle--2O4yI";
export var supplierItem = "single-module--supplierItem--2T_l4";
export var supplierItemMobile = "single-module--supplierItemMobile--3ympo";